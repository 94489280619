import { GTMEventData } from './types';

const throwInstallError = () => {
  throw 'GoogleTagManager is not installed, please install by calling installGoogleTagManager()';
};

/**
 * Pushes an event to Google Tag Manager.
 *
 * @param event - the GTM event data
 */
export const trackGTMEvent = (event: GTMEventData) => {
  if (!window.dataLayer) {
    throwInstallError();
  } else {
    window.dataLayer.push(event);
  }
};

/**
 * Installs Google Tag Manager on to the page.
 *
 * This must occur before tracking events.
 *
 * @param containerId - the GTM Container Id
 */
export const installGoogleTagManager = (containerId: string) => {
  // Only install the script if the data layer is not present
  if (!window.dataLayer) {
    // GTM will slurp any events pushed onto this array
    window.dataLayer = [];

    // Start up event - REQUIRED
    trackGTMEvent({
      event: 'gtm.js',
      'gtm.start': new Date().getTime()
    });

    // Load the GTM script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtm.js?id=${containerId}&l=dataLayer`;
    script.async = true;
    document.head.append(script);
  }
};
